* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}


.header{
  background-color: white;
  height: 7vw;
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
  
}
.header {
  background-color: #ffffff;
  padding: 1em;
}

.logoimage{
  width: 100%;
  
}

.navigation {
  display: flex;
  justify-content: flex-end;
}

.navigation ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-link {
  color: #000000;
  font-size: 1.2rem;
  text-decoration: none;
  text-transform: uppercase;
}

.header-upper-links {
  background-color: white;
  color: black;
}

.logo{
  width: 14vw;
  margin-left: 18%;
  height: 7vw;
  padding: 2%;
}

.header-inner{
width: 100%;
}

.footer{
  display: flex;
}

.home-wrapper-1{
  background-color: #AC2037;
}


.home-jumbotron{
  display: flex;
  background-color: white;
  font-weight: 500;
  line-height: 3;
  font-size: 1.2rem;
 
}

 ul{
  list-style: none;
 }

.jumbo{
width: 100%;
}

.video{
  display: flex;
  justify-content: center;
}

.register-button{
  background-color: white;
  color: #AC2037;
  font-weight: 500;
  font-size: 1.5rem;
  text-decoration: none;
}

.register-button:hover{
  background-color: #AC2037;
  color: white;
  border: 1px solid white;
  text-decoration: none;
}

.steps{
  font-weight: 600;
  font-size: 1.7rem;
  color: white;
  letter-spacing: 3px;
}

.step-button{
  background-color: white;
  text-decoration: none;
  color: gray;
  font-size: 2.5rem;
  padding: 2%;
  border-radius: 150%;
  width: 13%;
  height: 100%;
}

.step-button:hover{
  background-color: #AC2037;
  color: white;
  border: 1px solid white;
  text-decoration: none;
}

.home-image{
  width: 100%;
  height: 15vw;
  
}

.tittle{
  font-size: 2rem;
  text-align: start;
  cursor: pointer;
}

.bodytext{
  text-align: start;
  text-align: justify;
  font-size: 1rem;

}

.phone-image{
  width: 100%;
}

.accordion{
  width: 100%;
}

.item{
  background-color: white;
  margin-bottom: 2%;
  padding: 3%;
}

.content{
  max-height: 0;
  overflow: hidden;
  transition: all 0.05s cubic-bezier(0,1,0,1)
}

.content.show {
  height: auto;
  max-height: 99999px;
  transition: all 0.05s cubic-bezier(1,0,1,0);
  font-size: 1.5rem;
}